import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AiFillEye } from "react-icons/ai";
import { FaWrench } from "react-icons/fa";
import { BsFillTrashFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import {
  deleteNote,
  getNotesByFilter,
  getNotesByPage,
} from "../../app/reducers/Notes";
import { fetchBypage } from "../../app/reducers/Notes";
import ReactPaginate from "react-paginate";
import TableFilter from "./Table/TableFilter";

function NotesTable({ selectedNote, setSelectedNote }) {
  const notes = useSelector((state) => state.notes);
  const auth = useSelector((state) => state.auth);

  const [Filter, setFilter] = useState(null);

  useEffect(() => {
    if (Filter != null) {
      dispatch(
        getNotesByFilter({
          token: auth.user.api_token,
          search: Filter,
        })
      );
    }else{
      dispatch(
        getNotesByPage({
          token: auth.user.api_token,
          page: 1,
        })
      );
    }
  }, [Filter]);

  const handlePageClick = (event) => {
    dispatch(
      getNotesByPage({ token: auth.user.api_token, page: event.selected + 1 })
    );
  };

  const dispatch = useDispatch();

  const viewNote = (note) => {
    setSelectedNote({
      ...note,
      user: note.user.name,
    });
    document.getElementById("modalSeeNote").showModal();
  };
  const editNote = (note) => {
    setSelectedNote({
      ...note,
      user: note.user.name,
    });
    document.getElementById("modalEditNote").showModal();
  };

  const fetchBypage = (page) => {
    dispatch(
      fetchBypage({
        token: auth.user.api_token,
        page: page,
      })
    );
  };

  return (
    <div className="overflow-hidden border border-gray-200  rounded-[20px]">
      <div className="overflow-x-auto ">
        <div className="w-full h-full m-auto">
          <div className="bg-white rounded">
            <TableFilter Filter={Filter} setFilter={setFilter} />
            <table className="min-w-max w-full table-auto">
              <thead>
                <tr className=" text-gray-600 uppercase text-sm leading-normal">
                  <th className="py-3 px-6 text-left">Type</th>
                  <th className="py-3 px-6 text-left">Créateur</th>
                  <th className="py-3 px-6 text-center">Entreprise</th>
                  <th className="py-3 px-6 text-center">Montant</th>
                  <th className="py-3 px-6 text-center">Moyen de paiment</th>
                  <th className="py-3 px-6 text-center">DateDepense</th>
                  <th className="py-3 px-6 text-center">Remboursement</th>
                  <th className="py-3 px-6 text-center">Actions</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light">
                {notes.notes &&
                  notes.notes.map((note) => (
                    <tr
                      className="border-b border-gray-200 hover:bg-gray-100"
                      key={note.id}
                    >
                      <td className="py-3 px-6 text-center whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="mr-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              width="24"
                              height="24"
                              viewBox="0 0 48 48"
                            >
                              <path
                                fill="#80deea"
                                d="M24,34C11.1,34,1,29.6,1,24c0-5.6,10.1-10,23-10c12.9,0,23,4.4,23,10C47,29.6,36.9,34,24,34z M24,16	c-12.6,0-21,4.1-21,8c0,3.9,8.4,8,21,8s21-4.1,21-8C45,20.1,36.6,16,24,16z"
                              ></path>
                              <path
                                fill="#80deea"
                                d="M15.1,44.6c-1,0-1.8-0.2-2.6-0.7C7.6,41.1,8.9,30.2,15.3,19l0,0c3-5.2,6.7-9.6,10.3-12.4c3.9-3,7.4-3.9,9.8-2.5	c2.5,1.4,3.4,4.9,2.8,9.8c-0.6,4.6-2.6,10-5.6,15.2c-3,5.2-6.7,9.6-10.3,12.4C19.7,43.5,17.2,44.6,15.1,44.6z M32.9,5.4	c-1.6,0-3.7,0.9-6,2.7c-3.4,2.7-6.9,6.9-9.8,11.9l0,0c-6.3,10.9-6.9,20.3-3.6,22.2c1.7,1,4.5,0.1,7.6-2.3c3.4-2.7,6.9-6.9,9.8-11.9	c2.9-5,4.8-10.1,5.4-14.4c0.5-4-0.1-6.8-1.8-7.8C34,5.6,33.5,5.4,32.9,5.4z"
                              ></path>
                              <path
                                fill="#80deea"
                                d="M33,44.6c-5,0-12.2-6.1-17.6-15.6C8.9,17.8,7.6,6.9,12.5,4.1l0,0C17.4,1.3,26.2,7.8,32.7,19	c3,5.2,5,10.6,5.6,15.2c0.7,4.9-0.3,8.3-2.8,9.8C34.7,44.4,33.9,44.6,33,44.6z M13.5,5.8c-3.3,1.9-2.7,11.3,3.6,22.2	c6.3,10.9,14.1,16.1,17.4,14.2c1.7-1,2.3-3.8,1.8-7.8c-0.6-4.3-2.5-9.4-5.4-14.4C24.6,9.1,16.8,3.9,13.5,5.8L13.5,5.8z"
                              ></path>
                              <circle
                                cx="24"
                                cy="24"
                                r="4"
                                fill="#80deea"
                              ></circle>
                            </svg>
                          </div>
                          <span className="font-medium">
                            {note?.TypeDepense}
                          </span>
                        </div>
                      </td>
                      <td className="py-3 px-6 text-center">
                        <div className="flex items-center">
                          <span className="font-medium">
                            {note?.user?.name}
                          </span>
                        </div>
                      </td>
                      <td className="py-3 px-6 text-center">
                        <div className="flex items-center justify-center">
                          <span className="font-medium">{note?.Entreprise}</span>
                        </div>
                      </td>
                      <td className="py-3 px-6 text-center">
                        <span className="font-medium">
                          {note?.MontantDepense} €
                        </span>
                      </td>
                      <td className="py-3 px-6 text-center">
                        <span className="font-medium">
                          {note?.MoyenPaiement}
                        </span>
                      </td>
                      <td className="py-3 px-6 text-center">
                        <span className="font-medium">{note?.DateDepense}</span>
                      </td>
                      <td className="py-3 px-6 text-center">
                        {/* Color depends on if refunds.status is 1 or 2 or 3 */}
                        <p
                          className={`text-sm font-bold text-navy-700 note-status-${note?.RefundStatusId} p-1 rounded-lg`}
                        >
                          {note?.refunds?.status}
                        </p>
                      </td>
                      <td className="py-3 px-6 text-center">
                        {/* Voir,Modifier,Supprimer */}
                        <div className="flex item-center justify-center gap-4">
                          <div
                            className="bg-green-400 rounded-lg p-2 hover:cursor-pointer hover:scale-105"
                            onClick={() => {
                              viewNote(note);
                            }}
                          >
                            <AiFillEye size={20} color="white" />
                          </div>
                          <div
                            className="bg-yellow-400 rounded-lg p-2 hover:cursor-pointer hover:scale-105"
                            onClick={() => {
                              editNote(note);
                            }}
                          >
                            <FaWrench size={20} color="white" />
                          </div>
                          <div
                            className="bg-red-400 rounded-lg p-2 hover:cursor-pointer hover:scale-105"
                            onClick={() => {
                              if (
                                window.confirm(
                                  "Voulez-vous supprimer cette note ?"
                                )
                              ) {
                                // deleteNote
                                dispatch(
                                  deleteNote({
                                    token: auth.user.api_token,
                                    id: note.id,
                                  })
                                );
                              }
                            }}
                          >
                            <BsFillTrashFill size={20} color="white" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                {notes.notes.length === 0 && (
                  <tr>
                    <td colSpan="100%" className="text-center py-3 px-6">
                      Aucune note
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "right",
          width: "100%",
          height: "100%",
          margin: "auto",
        }}
      >
        <ReactPaginate
          breakLabel="..."
          previousLabel={
            <li>
              <a
                href="#"
                className="inline-flex w-12 items-center justify-center rounded border border-gray-100 bg-white text-gray-900 rtl:rotate-180"
              >
                <span className="sr-only">Prev Page</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </li>
          }
          nextLabel={
            <li>
              <a
                href="#"
                className="inline-flex w-12 items-center justify-center rounded border border-gray-100 bg-white text-gray-900 rtl:rotate-180"
              >
                <span className="sr-only">Next Page</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </li>
          }
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={notes.pagination.last_page}
          renderOnZeroPageCount={null}
          containerClassName={"pagination"}
          disabledClassName={"disabled-page"}
          activeClassName={"item active"}
          breakClassName={"item break-me"}
          pageClassName={"item"}
        />
      </div>
    </div>
  );
}

export default NotesTable;
