import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import MobileHeader from "./core/MobileHeader";
import { CiSquarePlus } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { addNote } from "../../app/reducers/Notes";
import FloatingLabelInput from "react-floating-label-input";
import Compressor from "compressorjs";

function MobileCreateNoteForm() {
  const location = useLocation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  // Vérifiez si location.state est défini et si noteType existe

  const [formNote, setFormNote] = useState({
    DateDepense: new Date().toISOString().slice(0, 10),
    TypeDepense: location.state.noteType.generique,
    Entreprise: "",
    MontantDepense: "",
    MoyenPaiement: "Carte",
    HT: "",
    Tva: "",
    Adresse: "",
    Fournisseur: "",
    Invite: "",
    Gestion: "",
  });

  const fileInputRef = useRef(null);

  const [selectedImage, setSelectedImage] = useState(null);

  const openGallery = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    compressImage(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const compressImage = (image) => {
    //using compressor js

    new Compressor(image, {
      quality: 0.3,
      success(result) {
        const reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onloadend = () => {
          // console log size

          setSelectedImage(reader.result);
        };
      },
      error(err) {
        console.log(err.message);
      },
    });
  }

  const validateForm = () => {
    let newErrors = {};

    if (!formNote.Entreprise.trim()) {
      newErrors.Entreprise = "L'entreprise est requise";
    }

    if (!formNote.MontantDepense) {
      newErrors.MontantDepense = "Le montant est requis";
    } else if (isNaN(formNote.MontantDepense) || formNote.MontantDepense <= 0) {
      newErrors.MontantDepense = "Le montant doit être un nombre positif";
    }

    if (!formNote.DateDepense) {
      newErrors.DateDepense = "La date est requise";
    }

    if (!selectedImage) {
      newErrors.Image = "Une image est requise";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);

    const data = {
      token: auth.user.api_token,
      note: {
        ...formNote,
        image: selectedImage,
      },
    };

    dispatch(
      addNote({
        token: data.token,
        note: data.note,
      })
    )
      .unwrap()
      .then(() => {
        window.location.href = "/";
      })
      .catch((error) => {
        console.error("Erreur lors de l'ajout de la note:", error);
        // Gérer l'erreur ici si nécessaire
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setFormNote((formNote) => ({
      ...formNote,
      HT: parseFloat(calculHt(formNote.MontantDepense, formNote.Tva)).toFixed(
        2
      ),
    }));
  }, [formNote.MontantDepense, formNote.Tva]);

  if (!location.state || !location.state.noteType) {
    // Gérer le cas où noteType n'est pas défini
    return (
      <div>
        L'objet noteType n'a pas été transmis correctement. Assurez-vous de
        naviguer depuis la page appropriée.
      </div>
    );
  }

  //function for calculate the ht price
  const calculHt = (montant, tva) => {
    return montant * (1 + tva / 100);
  };

  return (
    <div>
      <MobileHeader title="Ajouter une note" />
      <div className="border-2 border-solid rounded-md p-1 m-2">
        <div className="flex items-center justify-evenly">
          {selectedImage ? (
            <img
              src={selectedImage}
              alt="Selected"
              onClick={openGallery}
              className="w-[125px] h-[175px] cursor-pointer m-2"
            />
          ) : (
            <CiSquarePlus
              onClick={openGallery}
              className="cursor-pointer text-blue-400"
              size={175}
            />
          )}
          {/* Trois Permier Input */}
          <div className="my-4">
            <FloatingLabelInput
              id="entreprise"
              label="Entreprise"
              className="my-4 w-full "
              onBlur={(e) =>
                setFormNote({
                  ...formNote,
                  Entreprise: e.target.value,
                })
              }
            />
            {errors.Entreprise && <p className="text-red-500 text-sm">{errors.Entreprise}</p>}

            <input
              id="date"
              label="Date"
              className="border-b-2 border-solid rounded-md my-2  w-full"
              type="date"
              onBlur={(e) =>
                setFormNote({
                  ...formNote,
                  DateDepense: e.target.value,
                })
              }
              defaultValue={new Date().toISOString().slice(0, 10)}
            />
            {errors.DateDepense && <p className="text-red-500 text-sm">{errors.DateDepense}</p>}

            <FloatingLabelInput
              id="montant"
              label="Montant"
              type="number"
              className="my-2 w-full"
              onBlur={(e) =>
                setFormNote({
                  ...formNote,
                  MontantDepense: e.target.value,
                })
              }
            />
            {errors.MontantDepense && <p className="text-red-500 text-sm">{errors.MontantDepense}</p>}
          </div>
        </div>
        {/* Sous Partie de la premiere */}

        <div className="ml-2 flex items-center">
          <h3 className="text-blue-400 text-lg w-full">Moyen de paiement</h3>
          <select
            className="border-2 border-solid rounded-md p-1 w-full"
            onChange={(e) =>
              setFormNote({
                ...formNote,
                MoyenPaiement: e.target.value,
              })
            }
          >
            <option value="Carte" selected>
              Carte Bancaire
            </option>
            <option value="Virement">Virement</option>
            <option value="Cheque">Cheque</option>
            <option value="Espece">Espece</option>
          </select>
        </div>
        <div className="ml-2 flex items-center justify-start">
          <h3 className="text-blue-400 text-lg mr-2">TVA</h3>
          <span className="border-2 border-solid rounded-md p-1 my-2">
            <input
              type="number"
              className="w-5 focus:outline-none"
              placeholder="0"
              onChange={(e) =>
                setFormNote({
                  ...formNote,
                  Tva: e.target.value,
                })
              }
            />
            %
          </span>
        </div>
        <div className="m-2 flex items-center">
          <h3 className="text-blue-400 text-lg">Montant TTC</h3>
          <div>
            <input
              type="text"
              className="border-2 border-solid rounded-md p-1 m-2"
              placeholder="Montant HT"
              disabled
              // add euro symbole to input value
              value={formNote.HT + " €"}
            />
          </div>
        </div>
      </div>
      {/* Deuxieme Partie */}
      <div className="border-2 border-solid rounded-md p-1 m-2">
        <h3 className="text-blue-400 text-lg items-center">Détails</h3>
        <div className="m-2 flex items-center">
          <h3 className="text-blue-400 text-lg">Gestion</h3>
          <input
            type="text"
            className="border-2 border-solid rounded-md p-1 m-2"
            placeholder="Compte gestion"
            onChange={(e) =>
              setFormNote({
                ...formNote,
                Gestion: e.target.value,
              })
            }
          />
        </div>
        <div className="m-2 flex items-center">
          <h3 className="text-blue-400 text-lg">Invités</h3>
          <input
            type="text"
            className="border-2 border-solid rounded-md p-1 m-2"
            placeholder="Invités"
            onChange={(e) =>
              setFormNote({
                ...formNote,
                Invite: e.target.value,
              })
            }
          />
        </div>
        <div className="m-2 flex items-center">
          <h3 className="text-blue-400 text-lg">Type de Note</h3>
          <input
            type="text"
            className="border-2 border-solid rounded-md p-1 m-2"
            placeholder="Type de note"
            disabled
            value={location.state.noteType.generique}
          />
        </div>

        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileChange}
        />
      </div>
      {errors.Image && <p className="text-red-500 text-sm m-2">{errors.Image}</p>}
      <button
        className="bg-blue-400 text-white rounded-md p-2 m-2 disabled:opacity-50"
        onClick={handleSubmit}
        disabled={isLoading}
      >
        {isLoading ? "Chargement..." : "Ajouter"}
      </button>
    </div>
  );
}

export default MobileCreateNoteForm;
